import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";

import WorkSection from "./WorkSection"



const StyledSection = styled(Box)`

`





const Work = ({ title, title_color, items }) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection id="work" css={{ backgroundColor: "#efefef" }}>
        {items.map((item, index) => {
                    
                    var bg = "white";

                    if (index % 2 != 0) {
                      bg = "#efefef";
                    }

                    var pt=5

                    if (index == 0 ){
                      pt=0
                    }
          
          return (
            <>
              {index == 0 &&
              <Box style={{backgroundColor: bg}} pt={5}>
                <Container>
                  <Title color="black" className='pb-1'>
                    Work
                  </Title>
                </Container>
                </Box>

              }
              
            

              <WorkSection item={item} bg={bg} pt={pt}/>
            </>
          )
        })}


      </StyledSection>
    </>
  );
};

export default Work;

import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';

import 'swiper/swiper-bundle.css';


const ImgRight = styled.img`
  max-width: 100%;
`;

const StyledSection = styled(Section)`
position: relative;
height: 100%;
.font-light{
  font-weight: 300;
}
`

const BottomBox = styled(Box)`

position: absolute;
bottom: 50px;
@media ${device.sm} {
  bottom: 0px;
}
.text-bold{
  font-weight: bold;
}

`

const HeroSlideOld = ({ hero_image, title, sub_title }) => {
  return (
    <>
      <StyledSection className="position-relative" css={{ position: "relative"}}>
        <Container>
          <BottomBox>
            <Text
              color="white"
              className='font-light'
            >
              {title}
            </Text>
            <Text
              mb={4}
              color="white"
              mb="20px"
              className='text-bold'
            >
              {sub_title}
            </Text>

          </BottomBox>
        </Container>

        <img src={hero_image.localFile.url}/>

      </StyledSection>

    </>
  )
};


const HeroSlide = ({ hero_image, title, sub_title }) => {
  return (
    <>
    <div className="position-relative">

    <Container>
          <BottomBox>
            <Text
              color="white"
              className='font-light'
            >
              {title}
            </Text>
            <Text
              mb={4}
              color="white"
              mb="20px"
              className='text-bold'
            >
              {sub_title}
            </Text>

          </BottomBox>
        </Container>


    <img src={hero_image.localFile.url} style={{width: "100%", height: "100%"}}/>

    </div>

    </>
  )
};

const SwiperWrapper = styled.div`
padding-top: 76px;



.swiper-button-next, .swiper-container-rtl .swiper-button-prev{
  right: 60px;
}

.swiper-button-prev, .swiper-container-rtl .swiper-button-next{
  left: 60px;
}
.swiper-button-prev, .swiper-button-next{
  color: #a0c0c5 !important;
}


@media ${device.sm} {
  padding-top: 50px;

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev{
    right: 20px;
    display: none;
  }
  
  .swiper-button-prev, .swiper-container-rtl .swiper-button-next{
    left: 20px;
    display: none;

  }
}



`

SwiperCore.use([Autoplay, Navigation]);


const Hero = ({ carousel }) => {
  return (
    <>
      {/* <!-- Hero Area --> */}

      <SwiperWrapper>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 3000 }}
          navigation
          loop
        
        >
          {carousel.map((item, index) => (
            <SwiperSlide><HeroSlide hero_image={item.featured_image} title={item.headline.text} sub_title={item.subline.text} /></SwiperSlide>
          ))}
        </Swiper>

      </SwiperWrapper>
    </>
  );
};

export default Hero;

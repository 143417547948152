import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";




const StyledSection = styled(Box)`



`





const Tagline = ({title, color}) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <StyledSection pt={3} pb={3}>
        <Container>
          <Title mb={0} color={color} className='padding-fix'>
            {title}
          </Title>
        </Container>
      </StyledSection>
    </>
  );
};

export default Tagline;

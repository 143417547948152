import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";

import Img from "gatsby-image"

import { Link } from "gatsby";

const StyledSection = styled(Box)`
@media ${device.md} {
    .view-all{
        position: absolute;
        right: 15px;
        bottom: 0px;
        color: #9fc0c5;
        margin-bottom: 5px !important;
    
        border-bottom: 2px solid #9fc0c5;
    }
    
    
}

@media ${device.sm} {
    .view-all{
        color: #9fc0c5;
        display: inline-block;
        border-bottom: 2px solid #9fc0c5;
    }
    
    
}

.view-xx{
    position: absolute;
    right: 15px;
    bottom: 0px;
    color: #9fc0c5;
    border-bottom: 2px solid #9fc0c5;

}


@media ${device.sm} {
    .fix-col{
        justify-content: space-between;
        align-items: center;
    }
}


`

const OurWorkBox = styled(Box)`
.gatsby-image-wrapper{
    height: 250px;
}

.font-light{
    font-size: 20px;
    font-weight: 300;
    color: #6d6e71;
}



`

const WorkItem = ({ title, image, page_link }) => {
    return (
        <>

            <Col md={4} pt={4} className='col-md-offset-2s'>
                <Link to={page_link}>
                    <OurWorkBox my={3} data-aos="zoom-in"
                        data-aos-duration="550"
                        data-aos-once="true"
                        data-aos-delay="250"
                    >

                        <Img fluid={image} />

                        <Text className='pt-2 font-light'>
                            {title}
                        </Text>
                    </OurWorkBox>

                </Link>


            </Col>
        </>

    )
};


const StyledTitle = styled(Title)`

font-size: 42px;
a:hover{
    color: #9fc0c5!important
}
@media ${device.sm} {
    font-size: 18px !important;
    line-height: 25px !important;
    padding-top: 0px;
    margin-top: 0px;
    margin-bottom: 0px;

}


`


const WorkSection = ({ item, cat=false, bg="white", pt=0 }) => {
    
    if (!item) return null

    var document = {}

    if(cat){
        document = item

    }else{
        document = item.linked_category.document
    }

    if (!cat && pt != 0){
        pt = 5 
    }


    return (
        <>
            {/* <!-- Hero Area --> */}
            <StyledSection pt={pt} pb={5} style={{backgroundColor: bg}}>

            <Container>

                <Row className='position-relative'>
                    <Col className='fix-col'>
                    {cat > 0 &&
                    <Title>
Work
                    </Title>
                    }
                        <StyledTitle mb={3} mt={3} color="Black" variant="cardBig">
                        {!cat > 0 &&
                        <Link to={document.uid} css={`:hover{color: #9fc0c5}`}>
                            {document.data.category_name.text}
                        </Link>
                        }
                        {cat > 0 &&
                        document.data.category_name.text
                    }
                        </StyledTitle>


                        {!cat > 0 &&
                        <Link to={document.uid}>
                        <Text mb={3} mt={3} className='view-all'>
                            View All
                        </Text>
                    </Link>
  }

                    </Col>
                </Row>
                <Row className='gx-md-5'>
                    {document.data.featured_works.map((work_item, index) => (
                        <WorkItem title={work_item.featured_work.document.data.project_name.text} image={work_item.featured_work.document.data.hero_image.localFile.childrenImageSharp[0].fluid} page_link={`/work/${work_item.featured_work.document.uid}`} />
                    ))}
                    


                </Row>
                </Container>
            </StyledSection>
        </>
    );
};

export default WorkSection;
